import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';


import { NgForm } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MembershipService } from '@shared/services/membership/membership.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { PopoverRef } from '@tinc/components/popover/popover-ref';
import { AlertType } from '@shared/components/alert/alert.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { environment } from '@environments/environment';
import { DetectDeviceService } from '@shared/services/detectDevice.service';
import { BREAKPOINT_NAME } from '@shared/services/breakpoints.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FuseSharedModule } from '@fuse/shared.module';


@Component({
    selector: 'login-v2',
    templateUrl: './loginV2.component.html',
    styleUrls: ['./loginV2.component.scss'],
    encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
	standalone: true,
	imports: [ RouterModule,MatDividerModule,MatListModule,MatSlideToggleModule,		FuseSharedModule,MatButtonModule,MatCheckboxModule,	MatFormFieldModule,	MatIconModule,		MatInputModule ]
})
export class LoginV2Component implements OnInit {
    loginForm: FormGroup;
    isMobile:boolean = false;

    errorMessage: boolean = false;
    login: any = {};
    @ViewChild('loginForm') public _loginForm: NgForm;
    @Input() dontRedirect: boolean = false;
    @Input() accountDeletionMode: boolean = false;
    @Input() hideRegistration: boolean = false;

    @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private membershipService: MembershipService,
        private router: Router,
        private notify: NotificationService,
        private _fuseSidebarService: FuseSidebarService,
        private _detectDevice: DetectDeviceService,
    ) {
        this._detectDevice.subscribeToLayoutChanges().subscribe(observerResponse => {
			if (this._detectDevice.isBreakpointActive(BREAKPOINT_NAME.mobile)) {
				this.isMobile = this._detectDevice.isBreakpointActive(BREAKPOINT_NAME.mobile) ? true : false;
			}
		});
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                // navbar: {
                //     hidden: true
                // },
                // toolbar: {
                //     hidden: true
                // },
                // footer: {
                //     hidden: true
                // },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isMobile = this.membershipService.isMobileDevice;
		if ( this.accountDeletionMode ) {
			this.hideRegistration = true;
		}
    }

    onLoginSubmit() {
        if (this._loginForm.valid) {
            this.errorMessage = null;
            this.membershipService.login(this.login).subscribe(
                (data: any) => {
                    this.errorMessage = null;
                    // this._fuseNavigationService.reLoadMenu('main');
                    //this.close();
                    if (!this.dontRedirect){
                        this.router.navigateByUrl("/pages/dashboard");
                      this._fuseSidebarService.getSidebar('loginPanel').toggleOpen();
                    } else {
                        this.reload.emit(true);
                    }
                },
                err => {
                    this.errorMessage = true;
                    this.notify.openAlertModal((err.error ? err.error : "An error has occured"), AlertType.ERROR);
                }
            );
        } else {
            this.notify.openWarningModal("Please fill all fields");
        }
    }

    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('loginPanel').toggleOpen();
    }
}
